<template>
    
    <div v-if="arrayevents" class="h-auto w-full flex flex-col">
        
        <div class="h-auto p-2">

            <div class="h-auto w-full rounded-lg module p-2 flex flex-col">

                <div class="h-auto py-1 flex flex-row justify-start items-center">
                    <div v-if="eventSelected" class="h-5 w-5 bg-filters rounded-full mr-2 flex flex-col justify-center items-center" @click="eventSelected = !eventSelected">
                        <i class="mdi mdi-chevron-left"></i>
                    </div>
                    <span class="text-violet text-xs font-semibold">Events</span>
                </div>

                <div v-show="!eventSelected && !eventsSelected" class="h-auto mt-2 relative">
                    
                    <FullCalendar 
                        ref="fullCalendar"  
                        v-bind="options" 
                        @dayRender="dayRender"
                    />

                </div>

                <div v-if="eventSelected" class="mt-2">

                    <div class="h-full rounded-lg bg-box flex flex-col p-2 relative">

                        <div v-if="eventSelected.image" class="h-24 shadow w-full bg-white rounded-lg" :style="'background-image: url(' + imageRoute + eventSelected.image + '); ' +
                            'background-repeat: no-repeat; background-size: cover; background-position: center'"></div>

                        <div class="h-auto max-h-10 my-2">
                            <span class="text-white text-left text-sm truncate-2">{{ eventSelected.title }}</span>
                        </div>

                        <div class="h-auto py-1 rounded-lg bg-opacity-25" :class="{'bg-purple': eventSelected.organizer_group != 'ey', 'bg-aux': eventSelected.organizer_group == 'ey'}">

                            <div class="h-6 flex flex-row justify-start items-center px-2">
                                
                                <span class="text-xxs" :class="{'text-purple': eventSelected.organizer_group != 'ey', 'text-aux': eventSelected.organizer_group != 'ey'}">{{eventSelected.date | moment('DD MMMM')}}</span>
                                <span class="text-xxs text-font-gray ml-2" >{{eventSelected.date | moment('HH:mm')}}h</span>
                                <div class="h-full w-6 ml-auto flex flex-col justify-center items-center" @click="addtocalendar(eventSelected)">
                                    <i class="mdi mdi-calendar-plus text-xs" :class="{'text-purple': eventSelected.organizer_group != 'ey', 'text-aux': eventSelected.organizer_group != 'ey'}"></i>
                                </div>

                            </div>

                            <div v-if="eventSelected.address && eventSelected.address != ''" class="h-auto flex flex-row justify-start items-center px-2">
                                <i class="mdi mdi-map-marker text-xs mr-1" :class="{'text-purple': eventSelected.organizer_group != 'ey', 'text-aux': eventSelected.organizer_group != 'ey'}"></i>
                                <span class="text-xxs text-font-gray">{{eventSelected.address}}</span>
                            </div>

                        </div>

                        <div class="h-20 flex mt-1">

                            <div class="h-full w-1/2 flex flex-col">
                            
                                <div class="h-full w-full flex flex-col justify-center items-start">
                                    <span class="text-font-gray text-xxs">Socio Responsable</span>
                                    <span class="text-xs">{{ eventSelected.partner_name || '-' }}</span>
                                </div>

                                <div class="h-full w-full flex flex-col justify-center items-start">
                                    <span class="text-font-gray text-xxs">Organizador</span>
                                    <span class="text-xs">{{ eventSelected.organizer || '-' }}</span>
                                </div>
                            
                            </div>

                            <div class="h-full w-1/2 flex flex-col">
                            
                                <div class="h-full w-full flex flex-col justify-center items-start">
                                    <a v-if="eventSelected.contact_name" class="h-full w-full flex flex-col justify-center items-start" href="`mailto:${eventSelected.contact_email}">
                                        <span class="text-font-gray text-xxs">Contacto Marketing</span>
                                        <span class="text-xs"><i class="mdi mdi-email-outline" :class="{'text-purple': eventSelected.organizer_group != 'ey', 'text-aux': eventSelected.organizer_group == 'ey'}"></i> {{ eventSelected.contact_name }}</span>
                                    </a>
                               </div>

                                <div class="h-full w-full flex flex-col justify-center items-start">
                                    <span class="text-xs">{{ eventSelected.subsection }}</span>
                                </div>
                            
                            </div>

                        </div>

                        <div class="h-10 flex flex-row justify-start items-center">

                            <div v-if="eventSelected.guests && eventSelected.guests.length > 0" class="h-6 bg-font-gray-15 flex flex-row justify-start items-center rounded-lg px-2 cursor-pointer"
                                @click="openFile(eventSelected.guests, 'file')">
                                <i class="mdi mdi-download text-font-gray mr-1 text-xs"></i>
                                <span class="text-xxs text-font-gray font-semibold">Archivo</span>
                            </div>

                            <div v-if="eventSelected.more_info && eventSelected.more_info.length > 0" class="ml-auto h-6 bg-font-gray-15 flex flex-row justify-start items-center rounded-lg px-2 cursor-pointer"
                                @click="openFile(eventSelected.more_info, 'file')">
                                <i class="mdi mdi-information-variant text-font-gray text-xs"></i>
                            </div>

                        </div>

                    </div>

                </div>

                <div v-if="eventsSelected" class="mt-2">

                    <div class="h-6 flex flex-row justify-start items-center mb-2">

                        <div class="h-5 w-5 bg-filters rounded-full mr-2 flex flex-col justify-center items-center" @click="eventsSelected = false">
                            <i class="mdi mdi-chevron-left"></i>
                        </div>

                        <span class="text-xs">Events of {{eventsSelected[0].date | moment('DD MMM YYYY')}}</span>

                    </div>

                    <event v-for="(el, index) in eventsSelected" :key="index" view="mobile" :data="el" @selectEventChild="selectEventById" />

                </div>

                <div v-if="!eventSelected && !eventsSelected" class="mt-3 mb-2 flex flex-row justify-center items-center px-2">
                    <div class="flex items-center">
                        <div class="w-2 h-2 rounded-full bg-aux"></div>
                        <p class="ml-2 text-xs text-aux">Organizados por EY</p>
                    </div>

                    <div class="ml-6 flex items-center">
                        <div class="w-2 h-2 rounded-full bg-purple"></div>
                        <p class="ml-2 text-xs text-purple">Colaboraciones</p>
                    </div>
                </div>

                <div v-if="!eventSelected && !eventsSelected" class="h-auto py-1">
                    <span class="text-xxs text-font-gray">Próximos Eventos</span>
                </div>

                <div v-if="!eventSelected && !eventsSelected" class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput"
                        class="w-full h-6 text-xs rounded bg-body flex flex-row justify-start
                        items-center px-2">
                </div>

                <div v-if="!eventSelected && !eventsSelected && arrayevents" class="flex-1 min-h-0 p-2 overflow-auto">
                    <div class="scroll-gradient"></div>
                    <event v-for="(el, index) in search(arrayevents)" :key="index" view="mobile" :data="el" @selectEventChild="selectEventById" />
                </div>

            </div>

        </div>

    </div> 

    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>

</template>

<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
const event = () => import('@/components/Globals/PersonalDashboard/Events/Event');
import {state} from '@/store';

export default {
    components:{
        FullCalendar,
        event
    },
    data(){
        return{
            searchInput: '',
            demoEvents: {
                events: [],
                // color :'rgb(162, 121, 226, .15)',
                className : 'eyevents',
                timeFormat: 'H:mm',
            },
            calendarPlugins: [ dayGridPlugin ],
            demoEventsCol: {
                events: [],
                className: 'colaborations',
                timeFormat: 'H:mm',
            },
            mode: 'ey',
            loading: false,
            arrayevents: null,
            eventSelected: null,
            eventsSelected: false
        }
    },
    methods: {
        isActualDate(date) {
            return this.$moment(date).diff(this.$moment(new Date(new Date)), 'days') >= 0
        },
        openFile(file, type){
            this.$openFile(file, type) 
        },
        search(data){
            return this.$search(data, this.searchInput) 
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Event&sorts[date]=DESC&filters[active]=1', {params: state.globalParams});

                this.arrayevents = data;
                await this.$nextTick();

                await this.selectEventById(this.eventId);

                for (let index = 0; index < this.arrayevents.length; index++) {
                    if (this.arrayevents[index].organizer_group === 'ey') {
                        this.demoEvents.events.push(
                            {
                                ...this.arrayevents[index],
                                title: this.arrayevents[index].address,
                                description: this.arrayevents[index].permissions[0] ? this.arrayevents[index].permissions[0]['name'].split(" ").pop() : '',
                                start: this.arrayevents[index].date
                            }
                        )
                    }
                    else {
                        this.demoEventsCol.events.push(
                            {
                                ...this.arrayevents[index],
                                title: this.arrayevents[index].address,
                                description: this.arrayevents[index].permissions[0] ? this.arrayevents[index].permissions[0]['name'].split(" ").pop() : '',
                                start: this.arrayevents[index].date
                            }
                        )
                    }
                }
            } catch(error) {
            } finally {
                this.loading = false;
            }
        },
        eventClick(event) {

            if(event.length > 1){

                this.eventsSelected = false;
                this.eventsSelected = event;

            } else {

                this.eventSelected = false;

               this.selectEvent(event[0]);
                // if (this.eventId !== event[0].id) {
                //     this.$router.push({ name: 'EventsMobile', params: { id: event[0].id } });
                // } 

            }

        },
        async loadEvent(id) {
            if (!id) {
                return;
            }

            try {
                this.loadingEvent = true;
                const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'get?resource=Event&id=' + id);
                return data;
            } catch (error) {
            } finally {
                this.loadingEvent = false;
            }
        },
        selectEvent(event) { 
            if (event) {
                this.eventSelected = {
                    ...event,
                    start: event.date
                };
            } else {
                this.eventSelected = null;
            }
        },
        async selectEventById(id) {

            if (!id) {

                this.eventSelected = null;
                return;
                
            }   

            // const event = await this.loadEvent(this.eventId);
            // const event = await this.loadEvent(id.id);

            // if (event) {

                this.eventSelected = {
                    ...id,
                    start: id.date
                };

                this.goToDate(id.date);

            // } else {

            //     this.eventSelected = null;
                
            // }

        },
        goToDate(date) {
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.gotoDate(date);
        },
        addtocalendar(event) {
            const text = event.title;
            // var dates = event.start + '/' + event.end
            const dates = '1577869200000/1577955600000';
            const location = event.address;
            window.open(
                'https://calendar.google.com/calendar/r/eventedit?text=' + text + '&dates=' + dates + '&location=' + location + '&sf=true&output=xml'
                ,'_blank')
        },
        dayRender({ el, date }) {
            const events = this.arrayevents.filter(event => {
                const start = this.$moment(event.date).format('YYYY-MM-DD');
                const yesterday = this.$moment(date).subtract({ days: 1 }).format('YYYY-MM-DD');
                const tomorrow = this.$moment(date).add({ days: 1 }).format('YYYY-MM-DD');

                return this.$moment(start).isBetween(yesterday, tomorrow);
            });

            if (events.length > 0) {
                const event = events[0];
                const fromEy = event.organizer_group === 'ey';
                const badge = event.permissions.length > 0 ? event.permissions[0].name.split(' ')[1] : '';
                let boolDiv = ""

                if(events.length > 0) {
                    for(var i=0; i<events.length; i++){
                        boolDiv += `<div class="h-2px mb-1px w-full" style="background-color: ${ events[i].organizer_group == 'ey' ? '#13F2E3' : '#A279E2' }"></div>`
                    }
                }

                el.innerHTML = `
                    <div 
                        class="w-full h-full flex flex-col cursor-pointer border-none h-full rounded z-50"
                    >
                        <div class="px-1 py-1 flex items-baseline justify-between">
                            <div style="font-size: 10px;" class="leading-none">${ date.getDate() }</div>
                            
                        </div>

                        <div class="px-1">
                            ${boolDiv}
                        </div>
                    </div>
                `;

                el.addEventListener('click', () => {
                    this.eventClick(events);
                })
            } else {
                el.innerHTML = `
                    <div 
                        class="px-1 w-full h-full h-full rounded-lg"
                    >
                        <span style="font-size: 10px;">${ date.getDate() }</span>
                    </div>
                `;
            }
        }
    },
    computed: {
        imageRoute() {
            return this.$imageRoute 
        },
        options() {
            return {
                defaultView: 'dayGridMonth',
                events: this.events,
                plugins: this.calendarPlugins,
                height: 'parent',
                eventLimit: true,
                firstDay:1,
            }
        },
        events() {
            if (this.mode === 'ey') { return this.demoEvents }
            else { return this.demoEventsCol }
        },
        eventId() {
            return this.$route.params.id;
        }
    },
    watch: {
        async eventId(id) {
            await this.selectEventById(id);
        }
    },
    mounted() {
        this.load();
    }
}
</script>

<style>
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';

    .fc-header-toolbar h2 {
        @apply text-base;
    }

    .fc-header-toolbar button {
        @apply text-xs;
    }

    .fc-widget-header,
    .fc-widget-content {
        border: none !important;
    }

    .fc-widget-header table th {
        @apply pb-2;
        /* border-collapse: separate;
        border-spacing: 0 6px !important; */
    }

    .fc-widget-header table th {
        @apply text-xs text-white;
    }

    .fc-content-skeleton,
    .fc-content-skeleton table {
        display: none;
    }

    .fc-content-skeleton .fc-day-number {
        padding: 6px;
        float: left !important;
    }

    .fc th {
        border: none;
    }

    .fc-week {
        margin: 0 -6px;
    }

    .fc-day-grid .fc-row {
        min-height: 3rem !important;
    }

    .fc-week table {
        border-collapse: separate;
        border-spacing: 6px 2px;
        border: none;
    }

    .fc table .fc-day {
        @apply rounded-lg;
        border: none;
        background-color: #121822;
    }

    .fc-event {
        display: none;
    }
</style>